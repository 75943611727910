import React from 'react'
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { Button, Icon, Input, Dropdown, Menu, Switch } from 'antd'
import { NavLink } from "react-router-dom";

export default function ({ }) {
  return (
    <div className="header">


<img className="meettastic-home-logo" src="meettastic.png"></img>
      <Router>
        <NavLink exact activeClassName="home" to="/post">
          <Link to="/post">
          </Link>
        </NavLink>
      </Router>

      
      {/* <div className="menu">
        <Link to="/post"> <Button type="primary">
          Settings
      </Button></Link>
      </div> */}
    </div>
  )
}
